<template>
  <div class="contractDetail" v-if="data">
    <div class="font32 fontBold">商铺租赁合同</div>
    <van-notice-bar v-if="data && data.property_logs" style="padding: 0; margin: 20px 0" left-icon="volume-o"
      color="#FD6336" ackground="#ffffff" :text="data.property_logs[data.property_logs.length - 1].property_content" />
    <div class="font32 mt30">基础信息</div>
    <div v-if="data" class="infoBox mt30">
      <div class="dFlex mt30">
        <span class="op6">合同编号：</span>
        {{ data.contract_no }}
      </div>
      <!-- <div class="dFlex mt30">
        <span class="op6">合同文件：</span>
        <span @click="xqconfirm('check')">查看合同</span>
        <img src="@/assets/image/my/ckht.png" alt />
      </div> -->
      <div class="dFlex mt30">
        <span class="op6">合同开始时间：</span>
        {{ data.contract_start }}
      </div>
      <div class="dFlex mt30">
        <span class="op6">合同到期时间：</span>
        {{ data.contract_end }}
      </div>
      <div class="shopList mt30">
        <div class="list" v-for="(item, index) in data.merchants_stall" :key="item.contract_id">
          <div class="left">
            商铺{{ index + 1 }}：
            <span class="dC">{{ item.merchants_stall_name }}</span>
          </div>
          <div class="right">
            <div class="mt20" v-for="(items, indexs) in data.contract_fee_setting[index]" :key="indexs">
              <span class="op6">{{ items.contract_fee_name }}:</span>
              {{ items.pay_price }}
            </div>
          </div>
        </div>
      </div>
      <div class="dFlex mt30">
        <span class="op6">月总金额：:</span>
        <span class="fC">￥{{ data.month_price }}</span>
      </div>
      <div class="dFlex mt30">
        <span class="op6">租期内总金额：:</span>
        <span class="fC">￥{{ data.total_price }}</span>
      </div>
      <div class="dFlex mt30" v-for="item in data.dcList" :key="item.id">
        <span class="op6">{{ item.contract_fee_name }}:</span>
        <span class="fC">￥{{ item.pay_price }}</span>
      </div>
      <div class="dFlex mt30" style="align-items: flex-start">
        <span class="op6">经营范围：</span>
        {{ data.contract_manage }}
      </div>
      <div class="dFlex mt30" style="align-items: flex-start">
        <span class="op6" style="white-space: nowrap">违约金：</span>
        <div>
          逾期
          <span class="fC">{{ data.contract_overdue }}</span>天，按照逾期支付费用的
          <span class="fC">{{ data.contract_overdue_value }}%</span>支付违约金
        </div>
      </div>
      <div class="dFlex mt30">
        <span class="op6">递增率：</span>
      </div>
      <div class="feesTable">
        <table class="customTable" v-if="data">
          <tr>
            <th>递增年</th>
            <th>缴费项</th>
            <th>递增类型</th>
            <th>递增费用</th>
            <th>月总金额</th>
          </tr>
          <tr v-for="(item, index) in data.contract_increasing_temp" :key="index">
            <td :rowspan="item.rowspan" v-if="item.rowspan">第{{ parseInt(index / item.rowspan) + 1 }}年</td>
            <td>{{ item.item.name }}</td>
            <td>{{ item.item.change == 2 ? '固定金额' : '百分比' }}</td>
            <td>{{ item.item.contract_increasing }}{{ item.item.change == 2 ? '元' : '%' }}</td>
            <td>{{ Number(item.item.pay_price) }}</td>
          </tr>
        </table>
        <!-- <table class="customTable" v-if="data">
          <tr>
            <th>递增年</th>
            <th>递增率</th>
            <th>递增费用</th>
            <th v-for="(item, index) in data.contract_increasing[0].feesLit" :key="index">
              每月{{ item.name }}
            </th>
            <th>月总金额</th>
          </tr>
          <tr v-for="(item, index) in data.contract_increasing" :key="index">
            <td :rowspan="item.fees_list.length">第{{ index + 1 }}年</td>
            <td>{{ item.contract_increasing }}%</td>
            <td>{{ item.feeName.length == 0 ? '/' : item.feeName.join(',') }}</td>
            <td v-for="(list, j) in item.feesLit" :key="j">{{ list.pay_price }}</td>
            <td>{{ item.month_pay_price }}</td>
          </tr>
        </table> -->
      </div>
    </div>
    <div class="font32 fontBold mt30">缴费信息</div>
    <div class="infoTable">
      <div class="list">
        <div>费用类型</div>
        <div>费用金额</div>
        <div>缴费时间</div>
      </div>
      <template v-if="data">
        <div class="list" v-for="(item, index) in data.payList" :key="index">
          <template v-if="item">
            <div>{{ item.contract_fee_name }}</div>
            <div>
              <span v-if="item.is_derate">{{ parseFloat(Number(item.pay_price) - Number(item.derate_pay)).toFixed(2) }}
              </span>
              <span v-else>{{ Number(item.pay_price) }}</span>
            </div>
            <div>{{ item.pay_time }}</div>
          </template>
        </div>
      </template>
    </div>
    <!-- item.contract_status == 10 && item.contract_type == 10 && item.contract_renewal == 10 -->
    <!--  -->
    <div class="btns" v-if="contractStatus()">
      <div @click="handleInfoError">信息不对</div>
      <div @click="handlexq">确定</div>
      <!-- <mytoast @click="handlexq"></mytoast> -->
    </div>

    <!-- 工作人员列表 -->
    <rConfirm slots ref="usersref" @confirm="suberror" titles="提示">
      <div class="linkform pading-small">
        <van-cell-group inset>
          <span style="font-size: 14px">若续签信息有问题请直接与管理员电话联系，便于沟通。</span>
          <div class="link-box">
            <div v-for="item in userList" :key="item.user_id">
              <van-field v-model="item.name" readonly label="管理员姓名" />
              <van-field v-model="item.mobile" readonly label="联系电话">
                <template #button>
                  <div style="color: #81b51d" @click="$common.callPhone(item.mobile)">
                    <van-icon name="phone" />
                  </div>
                </template>
              </van-field>
            </div>
          </div>
        </van-cell-group>
      </div>
    </rConfirm>
    <!-- 续签联系人信息 -->
    <rConfirm slots ref="xqform" @confirm="xqconfirm" @close="xqformColse" titles="联系信息填写">
      <div class="linkform">
        <van-cell-group inset>
          <van-field v-model="xqlink.address" label="联系地址" placeholder="请输入联系地址" />
          <van-field v-model="xqlink.linkp" label="联系人电话" type="number" placeholder="请输入联系人电话" />
          <van-field v-model="xqlink.email" label="联系邮箱" placeholder="请输入联系邮箱" />
        </van-cell-group>
      </div>
    </rConfirm>
  </div>
  <div class="custom-image" image="default" v-else>
    <img src="../../assets/image/empty.png" alt="" />
    <p>还没有任何数据哦~</p>
    <van-loading size="32" color="#529100" v-if="showLoading">数据加载中</van-loading>
  </div>
</template>
<script setup>
import { Notify, NoticeBar, Toast } from 'vant';
import { ref, inject, reactive, toRefs } from 'vue';
const showLoading = ref(false);
let $require = inject('$require');
let { route, router } = inject('ctx');
let { myconfirm, showToast } = inject('plugin');
let data = ref(null);
// 获取信息
const getDetail = () => {
  showLoading.value = true;
  $require.HttpPost('/h5/contract/contract', { contract_id: route.query.id }).then((res) => {
    if (res.data) {
      if (res.data.contract.contract_rent_info) {
        const contractIncreasing = JSON.parse(res.data.contract.contract_increasing);
        let payFeesItem = [];
        contractIncreasing.forEach((item, i) => {
          item.fees_list.forEach((list, j) => {
            list.contract_rentInfo.forEach((data) => {
              payFeesItem.push({
                ...data,
                contract_fee_name: list.name
              })
            })
            // payFeesItem = payFeesItem.concat(list.contract_rentInfo);
          });
        });
        res.data.contract.payList = [].concat(
          // res.data.contract.contract_rent_info,
          payFeesItem,
          res.data.contract.dcList
        );
      }
      if (res.data.contract.contract_fee_setting) {
        res.data.contract.contract_fee_setting.forEach((item) => {
          item.splice(0, 1);
        });
      }
      res.data.contract.contract_increasing = res.data.contract.contract_increasing ? JSON.parse(res.data.contract.contract_increasing) : [];
      let temp = [];
      res.data.contract.contract_increasing.forEach((item, i) => {
        item.fees_list.forEach((list, j) => {
          if (j == 0) {
            temp.push({
              item: {
                ...list
              },
              ...item,
              rowspan: item.fees_list.length
            });
          } else {
            temp.push({
              item: {
                ...list
              },
              ...item
            });
          }
        });
      });
      res.data.contract.contract_increasing_temp = temp;
      rateList(res.data.contract.contract_increasing);
      console.log(res.data);
      data.value = res.data.contract;
      showLoading.value = false;

      console.log(data.value);
    }
  }).catch(() => {
    showLoading.value = false;
  });
};
getDetail();
// 信息不对
let usersref = ref(null);
let userList = ref('');
const handleInfoError = () => {
  $require.HttpPost('/h5/user/userList').then((res) => {
    if (res.data) {
      userList.value = res.data.list;
      usersref.value.fade();
    }
  });
};
// 提交错误
let suberror = () => {
  $require
    .HttpPost('/h5/contract/sendAbnormal', { contract_id: route.query.id })
    .then((res) => {
      usersref.value.close();
      router.push('/account/contract');
    })
    .catch((err) => {
      console.log(err);
      router.push('/account/contract');
    });
};
function copy (data) {
  let transfer = document.createElement('input');
  document.body.appendChild(transfer);
  transfer.value = data;
  transfer.focus();
  transfer.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  transfer.blur();
  document.body.removeChild(transfer);
  Notify({
    type: 'success',
    message: '复制成功',
    position: 'top'
  });
}
// 续签信息填写
let xqform = ref(null);
let xqlink = reactive({
  address: '',
  linkp: '',
  email: ''
});
const handlexq = () => {
  xqform.value.fade();
};
const xqconfirm = (check) => {
  if (!check && !xqlink.address) return Toast('请输入联系地址');
  if (!check && !xqlink.linkp) return Toast('请输入联系人');
  if (check) {
    router.push(`/account/contractSign?id=${route.query.id}&type=check`);
  } else {
    let obj = {
      contract_id: data.value.contract_id,
      merchants_address: xqlink.address,
      merchants_phone: xqlink.linkp,
      merchants_email: xqlink.email
    };
    $require.HttpPost('/h5/contract/writeMessage', obj).then((res) => {
      router.push(
        `/account/contractSign?id=${route.query.id}&merchants_address=${xqlink.address}&merchants_phone=${xqlink.linkp}&merchants_email=${xqlink.email}`
      );
    });
  }
};
const contractStatus = () => {
  if (data.value && data.value.contract_type == 10) {
    if (data.value.contract_user_is_agree == 20) {
      return true;
    }
    // if(data.value.contract_status == 10 && data.value.contract_renewal == 30){
    //   return true
    // }
    if (data.value.contract_status == 0 && data.value.contract_user_status != 10) {
      return true;
    }
    return false;
  }
  return false;
};
const xqformColse = () => {
  for (let i in xqlink) {
    xqlink[i] = '';
  }
};
// 递增费用
const rateList = (data) => {
  console.log('递增数据', data);
  if (!Array.isArray(data)) return;
  let arr = data[0].fees_list;
  data.forEach((item) => {
    item.feeName = [];
    item.ratehMoney = [];
    item.fees_list?.forEach((items) => {
      item.ratehMoney.push(items.pay_price);
      arr.forEach((its) => {
        if (its.id == items.id && items.pay_price != its.pay_price) {
          item.feeName.push(items.name);
        }
      });
    });
  });
};
</script>
<style lang="scss" scoped>
.contractDetail {
  width: 100vw;
  min-height: 100vh;
  padding: 25px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: black;

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0 50px 0;

    div {
      width: 250px;
      height: 96px;
      text-align: center;
      line-height: 96px;
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 48px;
      font-size: 32px;
      font-weight: 500;
      color: #000000;
      opacity: 0.6;

      &:nth-of-type(2) {
        background: #85c226;
        color: #ffffff;
        margin-left: 60px;
        opacity: 1;
      }
    }
  }

  .dC {
    color: #85c226;
  }

  .fC {
    color: #fd6336;
  }

  .dFlex {
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .b120 {
    display: block;
    min-width: 200px;
    text-align: right;
  }

  .infoTable {
    font-size: 24px;
    background: #ffffff;
    border-radius: 10px;

    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(1) {
        div {
          color: #8d8d8d;
        }
      }

      div {
        text-align: center;
        height: 60px;
        line-height: 60px;
        border-right: 1px solid #efefef;
        border-bottom: 1px solid #efefef;

        &:nth-of-type(1) {
          width: 25%;
        }

        &:nth-of-type(2) {
          width: 25%;
        }

        &:nth-of-type(3) {
          width: 50%;
          border-right: none;
        }
      }

      &:last-child {
        div {
          border-bottom: none;
        }
      }
    }
  }

  .infoBox {
    height: auto;
    padding: 40px 30px;
    box-sizing: border-box;
    font-size: 28px;
    color: black;
    background: #ffffff;
    border-radius: 10px;

    .shopList {
      padding: 30px;
      box-sizing: border-box;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;

      .list {
        display: flex;
        align-items: flex-start;

        .left {
          width: 40%;
        }

        .right {
          width: 60%;
        }
      }
    }

    // table {
    //   width: 100%;
    //   overflow: auto;
    //   tr {
    //     // width: 100%;
    //     height: 60px;
    //     td {
    //       height: 100%;
    //       width: 300px;
    //       line-height: 60px;
    //       text-align: center;
    //       border: 1px solid #eeeeee;
    //       // background: red;
    //     }
    //   }
    // }
    .feesTable {
      width: 100%;
      overflow: auto;
      border-radius: 10px;

      .customTable {
        overflow: auto;

        tr {
          width: 100%;
        }

        th,
        td {
          padding: 0 30px;
          width: 400px;
          height: 50px;
          border: 1px solid #eeebeb;
          white-space: nowrap;
        }
      }
    }
  }

  .copy {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #85c226;
  }
}

.pading-small {
  padding: 0 24px;
}

.link-box {
  max-height: 500px;
  overflow: auto;
}
</style>
